import { createSlice } from "@reduxjs/toolkit";

const sportsSlice = createSlice({

    name: 'sportsDetails',

    initialState: {
        sportsList: [],
        currentSports: null
    },

    reducers: {

        handleSportsList: (state, action) => {

            const data = action.payload

            state.sportsList = data

            if(!state.currentSports) {
                state.currentSports = data[0]
            }
            
        }, 

        changeCurrentSports: (state, action) => {

            const sports = action.payload

            for(let i = 0; i < state.sportsList.length; i++) {

                if(state.sportsList[i].name === sports) {
                    state.currentSports = state.sportsList[i]
                    break
                }

            }

            

        }
            
    }

})

export const { handleSportsList, changeCurrentSports } = sportsSlice.actions

export default sportsSlice.reducer