import {
  axiosClient,
  newAxiosClient,
  userAxiosClient,
  usqClient,
} from "../services/api";
import { ENDPOINTS } from "../constants/constants";
import { errorLog } from "./log-util";
import axios from "axios";

export const login = async (data) => {
  const endpoint = ENDPOINTS.login;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchVideos = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchVideos(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchPackages = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status,
  type
) => {
  const endpoint = ENDPOINTS.fetchPackages(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status,
    type
  );

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchPages = async (name, status, sportsId) => {
  const endpoint = ENDPOINTS.fetchPages(name, status, sportsId);

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getVideoDetail = async (id) => {
  const endpoint = ENDPOINTS.getVideoDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getPackageDetail = async (id) => {
  const endpoint = ENDPOINTS.getPackageDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getPageDetail = async (id) => {
  const endpoint = ENDPOINTS.getPageDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchPackageContent = async (id) => {
  const endpoint = ENDPOINTS.fetchPackageContent(id);

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateVideo = async (data) => {
  const endpoint = ENDPOINTS.updateVod;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updatePackage = async (data) => {
  const endpoint = ENDPOINTS.updatePackage;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateRanks = async (data) => {
  const endpoint = ENDPOINTS.updateRank;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const linkContentToPackage = async (data) => {
  const endpoint = ENDPOINTS.addVideoToPlaylist;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updatePage = async (data) => {
  const endpoint = ENDPOINTS.updatePage;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createPage = async (data) => {
  const endpoint = ENDPOINTS.createPage;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createVod = async (data) => {
  const endpoint = ENDPOINTS.createVod;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createPackage = async (data) => {
  const endpoint = ENDPOINTS.createPackage;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchLeagueList = async () => {
  const endpoint = ENDPOINTS.fetchLeagueList;

  try {
    const response = await axiosClient().post(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const saveKadambaMapping = async (data) => {
  const endpoint = ENDPOINTS.saveKadambaMapping;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchSportsList = async (data) => {
  const endpoint = ENDPOINTS.fetchSportsList;

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchMatches = async (
  id,
  status,
  pageNumber,
  leagueId,
  featured = 0,
  fantasy11 = 0,
  is_published = 1
) => {
  const endpoint = ENDPOINTS.fetchMatches(
    id,
    status,
    pageNumber,
    leagueId,
    featured,
    fantasy11,
    is_published
  );

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchVenueList = async (data) => {
  const endpoint = ENDPOINTS.fetchVenueList;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const saveVenueMapping = async (data) => {
  const endpoint = ENDPOINTS.saveVenueMapping;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchTeamList = async (data) => {
  const endpoint = ENDPOINTS.fetchTeamList;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const saveTeamMapping = async (data) => {
  const endpoint = ENDPOINTS.saveTeamMapping;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchMatchList = async () => {
  const endpoint = ENDPOINTS.fetchMatchList;

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const saveMatchMapping = async (data) => {
  const endpoint = ENDPOINTS.saveMatchMapping;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchPlayerList = async (data) => {
  const endpoint = ENDPOINTS.fetchPlayerList;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const savePlayerMapping = async (data) => {
  const endpoint = ENDPOINTS.savePlayerMapping;

  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateMatch = async (sportsId, matchId, data) => {
  const endpoint = ENDPOINTS.updateMatch(sportsId, matchId);

  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const feedUpdate = async (sportsId) => {
  const endpoint = ENDPOINTS.feedUpdate(sportsId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const leagueUpdate = async (sportsId) => {
  const endpoint = ENDPOINTS.leagueUpdate(sportsId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getMonksData = async (sportsId) => {
  const endpoint = ENDPOINTS.monkData;

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchTeams = async (
  sportsId,
  pageNumber = 1,
  teamName,
  leagueId
) => {
  const endpoint = ENDPOINTS.fetchTeams(
    sportsId,
    pageNumber,
    teamName,
    leagueId
  );

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateTeam = async (sportsId, teamId, data) => {
  const endpoint = ENDPOINTS.updateTeam(sportsId, teamId);

  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchSportsLeague = async (sportsId) => {
  const endpoint = ENDPOINTS.fetchSportsLeague(sportsId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchSportsTeam = async (sportsId) => {
  const endpoint = ENDPOINTS.fetchTeams(sportsId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchPlayers = async (
  sportsId,
  pageNumber = 1,
  teamId,
  playerName
) => {
  const endpoint = ENDPOINTS.fetchPlayers(
    sportsId,
    pageNumber,
    teamId,
    playerName
  );

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updatePlayer = async (sportsId, playerId, data) => {
  const endpoint = ENDPOINTS.updatePlayer(sportsId, playerId);

  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createStory = async (data) => {
  const endpoint = ENDPOINTS.createStory;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchStories = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchStories(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getStoryDetail = async (id) => {
  const endpoint = ENDPOINTS.getStoryDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateStory = async (data) => {
  const endpoint = ENDPOINTS.updateStory;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteStory = async (data) => {
  const endpoint = ENDPOINTS.deleteStory;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteVideo = async (data) => {
  const endpoint = ENDPOINTS.deleteVideo;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deletePackage = async (data) => {
  const endpoint = ENDPOINTS.deletePackage;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deletePage = async (data) => {
  const endpoint = ENDPOINTS.deletePage;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const linkVideoToMatch = async (data) => {
  const endpoint = ENDPOINTS.linkVideoToMatch;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const unlinkVideoToMatch = async (data) => {
  const endpoint = ENDPOINTS.unlinkVideoToMatch;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchMatchVideos = async (matchId, leagueId) => {
  const endpoint = ENDPOINTS.getMatchVideos(matchId, leagueId);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const clearCache = async (data) => {
  const endpoint = ENDPOINTS.clearCache;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const clearMatchUpCache = async (id) => {
  const endpoint = ENDPOINTS.clearMatchUpCache;
  let payload = {};
  if (id) {
    payload = {
      match_id: parseInt(id),
    };
  }

  try {
    const response = await axiosClient().post(endpoint, payload);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchSquad = async (sportsId, matchId) => {
  const endpoint = ENDPOINTS.fetchSquad(sportsId, matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updatePoints = async (payload) => {
  const endpoint = ENDPOINTS.updateUserPoints;
  try {
    const response = await userAxiosClient().patch(endpoint, payload);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getFantasyPoints = async (matchId) => {
  const endpoint = ENDPOINTS.getPlayerPoints(matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const bulkUpload = async (data) => {
  const endpoint = ENDPOINTS.bulkUpload;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateStatus = async (data) => {
  const endpoint = ENDPOINTS.updateStatus;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updatePlaying = async (sportsId, matchId, data) => {
  const endpoint = ENDPOINTS.updatePlaying(sportsId, matchId);

  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateFantasy = async (sportsId, matchId, data) => {
  const endpoint = ENDPOINTS.updateFantasy(sportsId, matchId);

  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const publishFantasy = async (sportsId, matchId, data) => {
  const endpoint = ENDPOINTS.publishFantasy(sportsId, matchId);

  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createLiveStream = async (data) => {
  const endpoint = ENDPOINTS.createLiveStream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateLiveStream = async (data) => {
  const endpoint = ENDPOINTS.updateLiveStream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getLiveStreamDetail = async (streamId) => {
  const endpoint = ENDPOINTS.getLiveStreamDetail(streamId);

  try {
    const res = await newAxiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchLiveStreams = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchLiveStreams(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const res = await newAxiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteLiveStream = async (data) => {
  const endpoint = ENDPOINTS.deleteLiveStream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAudios = async () => {
  const endpoint = ENDPOINTS.fetchAudios;

  try {
    const res = await axios.get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchBlogDetails = async (data) => {
  const endpoint = ENDPOINTS.fetchBlogDetails;

  try {
    const res = await axios.post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAppConfigs = async (pageNumber) => {
  const endpoint = ENDPOINTS.fetchAppConfigs(pageNumber);

  try {
    const res = await axiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createAppConfig = async (data) => {
  const endpoint = ENDPOINTS.createAppConfig;

  try {
    const res = await axiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAppConfigDetail = async (configId) => {
  const endpoint = ENDPOINTS.fetchAppConfigDetail(configId);

  try {
    const res = await axiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateAppConfig = async (data) => {
  const endpoint = ENDPOINTS.updateAppConfig(data.id);

  try {
    const res = await axiosClient().patch(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchMappedLeagues = async () => {
  const endpoint = ENDPOINTS.fetchMappedLeagues;

  try {
    const res = await axiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchBlogs = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchBlogs(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getBlogDetail = async (id) => {
  const endpoint = ENDPOINTS.getBlogDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateBlog = async (data) => {
  const endpoint = ENDPOINTS.updateBlog;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createBlog = async (data) => {
  const endpoint = ENDPOINTS.createBlog;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const deleteBlog = async (data) => {
  const endpoint = ENDPOINTS.deleteBlog;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchYoutubeVideos = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchYoutubeVideos(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getYoutubeVideoDetail = async (id) => {
  const endpoint = ENDPOINTS.getYoutubeVideoDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateYoutubeVideo = async (data) => {
  const endpoint = ENDPOINTS.updateYoutubeVideo;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createYoutubeVideo = async (data) => {
  const endpoint = ENDPOINTS.createYoutubeVideo;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const deleteYoutubeVideo = async (data) => {
  const endpoint = ENDPOINTS.deleteYoutubeVideo;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const pullFantasy = async (sportsId, matchId) => {
  const endpoint = ENDPOINTS.pullFantasy(sportsId, matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const pullFantasyGL = async (sportsId, matchId) => {
  const endpoint = ENDPOINTS.pullFantasyGL(sportsId, matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getFantasyLogs = async (sportsId, matchId) => {
  const endpoint = ENDPOINTS.getFantasyLogs(sportsId, matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createBanner = async (data) => {
  const endpoint = ENDPOINTS.createBanner;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const deleteBanner = async (data) => {
  const endpoint = ENDPOINTS.deleteBanner;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getBannerDetail = async (id) => {
  const endpoint = ENDPOINTS.getBannerDetail(id);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUsersDump = async () => {
  const endpoint = ENDPOINTS.fetchUsersDump;

  try {
    const response = await axiosClient().get(endpoint, {
      responseType: "blob",
    });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUsersNewDump = async () => {
  const endpoint = ENDPOINTS.fetchUsersNewDump;

  try {
    const response = await userAxiosClient().get(endpoint, {
      responseType: "blob",
    });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUsersDumpCount = async (startDate, endDate) => {
  const endpoint = ENDPOINTS.fetchUsersDumpCount(startDate, endDate);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};
export const fetchVuLeadDump = async (startDate, endDate) => {
  const endpoint = ENDPOINTS.fetchVuLeadsDump(startDate, endDate);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUsersNewDumpCount = async (startDate, endDate) => {
  const endpoint = ENDPOINTS.fetchUsersDumpCount(startDate, endDate);

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchMasterSubscription = async (type) => {
  const endpoint = ENDPOINTS.getMasterSubscription(type);
  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteMasterSubs = async (data) => {
  const endpoint = ENDPOINTS.masterSubscription;
  try {
    const response = await userAxiosClient().delete(endpoint, { data });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createUpdateMasterSubs = async (data, isEdit) => {
  const endpoint = ENDPOINTS.masterSubscription;
  let response;
  try {
    if (isEdit) {
      response = await userAxiosClient().patch(endpoint, data);
    } else {
      response = await userAxiosClient().post(endpoint, data);
    }
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const toggleMasterSubStatus = async (data) => {
  const payload = {
    master_subscription_id: data.id,
    is_active: !data.is_active,
  };
  const endpoint = ENDPOINTS.toggleMasterSubs;
  try {
    const response = await userAxiosClient().post(endpoint, { ...payload });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getSubscriptions = async (props) => {
  const endpoint = ENDPOINTS.getSubscritionList(
    Number(props.type_id),
    props.type
  );
  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteSubscription = async (data) => {
  const endpoint = ENDPOINTS.adminSubscription;
  try {
    const response = await userAxiosClient().delete(endpoint, { data });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createOrEditSubscription = async (data, isEdit) => {
  const endpoint = ENDPOINTS.adminSubscription;
  try {
    if (isEdit) {
      const response = await userAxiosClient().patch(endpoint, { ...data });
      return response.data;
    } else {
      const response = await userAxiosClient().post(endpoint, { ...data });
      return response.data;
    }
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const toggleSubsStatus = async (data) => {
  const endpoint = ENDPOINTS.toggleSubscriptionStatus;
  try {
    const response = await userAxiosClient().post(endpoint, { ...data });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchBanners = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchBanners(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateBanner = async (data) => {
  const endpoint = ENDPOINTS.updateBanner;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createStream = async (data) => {
  const endpoint = ENDPOINTS.createStream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateStream = async (data) => {
  const endpoint = ENDPOINTS.updateStream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getStreamDetail = async (streamId) => {
  const endpoint = ENDPOINTS.getStreamDetail(streamId);

  try {
    const res = await newAxiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAllStreams = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchAllStreams(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const res = await newAxiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteStream = async (data) => {
  const endpoint = ENDPOINTS.deleteStream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateSchedule = async (data) => {
  const endpoint = ENDPOINTS.updateSchedule;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createMatchstream = async (data) => {
  const endpoint = ENDPOINTS.createMatchstream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateMatchstream = async (data) => {
  const endpoint = ENDPOINTS.updateMatchstream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getMatchstreamDetail = async (streamId) => {
  const endpoint = ENDPOINTS.getMatchstreamDetail(streamId);

  try {
    const res = await newAxiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const changePublishTime = async (sportsId, matchId, data) => {
  const endpoint = ENDPOINTS.changePublishTime(sportsId, matchId);

  try {
    const res = await axiosClient().patch(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAllMatchstreams = async (
  pageNumber,
  pageSize,
  title,
  sportsId,
  status
) => {
  const endpoint = ENDPOINTS.fetchAllMatchstreams(
    pageNumber,
    pageSize,
    title,
    sportsId,
    status
  );

  try {
    const res = await newAxiosClient().get(endpoint);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteMatchstream = async (data) => {
  const endpoint = ENDPOINTS.deleteMatchstream;

  try {
    const res = await newAxiosClient().post(endpoint, data);
    return res.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getMatchDetail = async (sportsId, matchId) => {
  const endpoint = ENDPOINTS.getMatchDetail(sportsId, matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getMatchf11Config = async (matchId) => {
  const endpoint = ENDPOINTS.matchF11Config(matchId);
  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getConfigJSON = async () => {
  const endpoint = `${ENDPOINTS.fetchF11BaseConfig}?${Date.now()}`;
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const saveConfigJSON = async (data) => {
  const endpoint = ENDPOINTS.updateMatchF11Config;
  try {
    const response = await axiosClient().put(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const unblockUser = async (phoneNumber, countryCode) => {
  const endpoint = ENDPOINTS.unblockUser(phoneNumber, countryCode);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchFantasyteamDetail = async (sportsId, matchId) => {
  const endpoint = ENDPOINTS.getFantasyteamDetail(sportsId, matchId);

  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const linkHighlights = async (data) => {
  const endpoint = ENDPOINTS.linkHighlights;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const unlinkHighlights = async (data) => {
  const endpoint = ENDPOINTS.unlinkHighlights;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getHighlights = async (matchId) => {
  const endpoint = ENDPOINTS.getHighlights(matchId);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUsers = async (
  pageNumber,
  pageSize,
  searchType,
  searchQuery
) => {
  const endpoint = ENDPOINTS.fetchUsers(
    pageNumber,
    pageSize,
    searchType,
    searchQuery
  );

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateUserBlockStatus = async (data) => {
  const endpoint = ENDPOINTS.updateUserBlockStatus;

  try {
    const response = await userAxiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUserProfileList = async (userId) => {
  const endpoint = ENDPOINTS.fetchUserProfileList(userId);

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};
export const fetchUserTransactions = async (
  user_id,
  page,
  page_size,
  date,
  withFilter
) => {
  let endpoint;
  if (withFilter) {
    endpoint = ENDPOINTS.getTrxnWithFilters(
      user_id,
      page,
      page_size,
      date.s_date,
      date.e_date
    );
  } else {
    endpoint = ENDPOINTS.getTransactions(user_id, page, page_size);
  }

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUserOrder = async (
  user_id,
  page_size,
  page,
  type = "",
  type_id = ""
) => {
  const endpoint = ENDPOINTS.userOrderFilter(
    user_id,
    page_size,
    page,
    type,
    type_id
  );

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchUserDeviceList = async (userId) => {
  const endpoint = ENDPOINTS.fetchUserDeviceList(userId);

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchSettingList = async () => {
  const endpoint = ENDPOINTS.fetchSettingList;

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateSetting = async (data) => {
  const endpoint = ENDPOINTS.updateSetting;

  try {
    const response = await userAxiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getWalletUserDetails = async (userId) => {
  const endpoint = ENDPOINTS.userWallet(userId);
  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const addToUserWallet = async (data) => {
  const endpoint = ENDPOINTS.addUserWallet;
  try {
    const response = await userAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateGiftCredit = async (data) => {
  const endpoint = ENDPOINTS.giftCredit;
  try {
    const response = await userAxiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getKadambaMatchReport = async (endDate, startDate) => {
  const endpoint = ENDPOINTS.getkadambaMatchReport(endDate, startDate);

  try {
    const response = await axiosClient().get(endpoint, {
      responseType: "blob",
    });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const validateTxnAndOrder = async (type, id) => {
  let endpoint;
  if (type == "order") {
    endpoint = ENDPOINTS.validateOrder(id);
  } else {
    endpoint = ENDPOINTS.validateTxn(id);
  }

  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAssets = async (pageNumber, type, pageSize) => {
  const endpoint = ENDPOINTS.assetsList(pageNumber, type, pageSize);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchAsset = async (id) => {
  const endpoint = ENDPOINTS.getAsset(id);

  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createAsset = async (data) => {
  const endpoint = ENDPOINTS.createAsset;

  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateAsset = async (data) => {
  const endpoint = ENDPOINTS.updateAsset;

  try {
    const response = await newAxiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteAsset = async (data) => {
  const endpoint = ENDPOINTS.deleteAsset;

  try {
    const response = await newAxiosClient().delete(endpoint, { data });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const createFantasyTip = async (data) => {
  const endpoint = ENDPOINTS.createFantasyTip;
  try {
    const response = await newAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateFantasyTip = async (data) => {
  const endpoint = ENDPOINTS.updateFantasyTip;
  try {
    const response = await newAxiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getFantasyTip = async (matchId, leagueId, sportsId) => {
  const endpoint = ENDPOINTS.getFantasyTip(matchId, leagueId, sportsId);
  try {
    const response = await newAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteFantasyTip = async (data) => {
  const endpoint = ENDPOINTS.deleteFantasyTip;
  try {
    const response = await newAxiosClient().delete(endpoint, { data });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getMatchTabs = async (matchId) => {
  const endpoint = ENDPOINTS.getMatchTabs(matchId);
  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const submitMatchTabsConfig = async (data) => {
  const endpoint = ENDPOINTS.matchTabs;
  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getCreditPackages = async () => {
  const endpoint = ENDPOINTS.creditPackages;
  try {
    const response = await userAxiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteCreditPackage = async (packageId) => {
  const endpoint = ENDPOINTS.deleteCreditPackage(packageId);
  try {
    const response = await userAxiosClient().delete(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const creditPackageCreateUpdate = async (data, isEdit) => {
  const endpoint = ENDPOINTS.creditPackages;
  let response;
  try {
    if (isEdit) {
      response = await userAxiosClient().patch(endpoint, data);
    } else {
      response = await userAxiosClient().post(endpoint, data);
    }
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getAssociateList = async (data) => {
  const endpoint = ENDPOINTS.fetchAssociateList;
  try {
    const response = await usqClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const verifyAssociate = async (data) => {
  const endpoint = ENDPOINTS.verifyAssociate;
  try {
    const response = await usqClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const downloadAllAssociates = async (status) => {
  const endpoint = ENDPOINTS.downloadAllAssociates(status);
  try {
    const response = await usqClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getStudentList = async (data) => {
  const endpoint = ENDPOINTS.fetchStudentList;
  try {
    const response = await usqClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const downloadStudentsList = async () => {
  const endpoint = ENDPOINTS.downloadAllStudentList;
  try {
    const response = await usqClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const fetchTrialOptions = async () => {
  const endpoint = ENDPOINTS.getTrailOptions;
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const purchaseTrailOptionForUser = async (data) => {
  const endpoint = ENDPOINTS.purchaseTrialSubscription;
  try {
    const response = await userAxiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const buildNotificationTemplate = async (data) => {
  const endpoint = ENDPOINTS.createNotificationTemplate;
  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const getNotificationTemplates = async () => {
  const endpoint = ENDPOINTS.getNotificationsTemplates;
  try {
    const response = await axiosClient().get(endpoint);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const deleteNotificationTemplate = async (data) => {
  const endpoint = ENDPOINTS.deleteNotificationTemplate;
  try {
    const response = await axiosClient().delete(endpoint, { data });
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updateNotificationTemplate = async (data) => {
  const endpoint = ENDPOINTS.editNotificationTemplate;
  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const sendNotification = async (data) => {
  const endpoint = ENDPOINTS.broadCastNotification;
  try {
    const response = await axiosClient().post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const updatePlayerPositionsAPI = async (data) => {
  const endpoint = ENDPOINTS.leagueUpdatePlayerPosition;
  try {
    const response = await axiosClient().patch(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const bulkUploadUsersforNotification = async (data) => {
  const endpoint = ENDPOINTS.bulkUploadNotification;
  const myClient = axiosClient();

  const modifiedClient = axios.create({
    ...myClient.defaults, // Copy default configuration
    headers: {
      ...myClient.defaults.headers, // Copy default headers
      "Content-Type": "multipart/form-data", // Important for file uploads
    },
  });

  try {
    const response = await modifiedClient.post(endpoint, data);
    return response.data;
  } catch (err) {
    errorLog(err);
    throw err;
  }
};

export const convertSecondsToTimeString = (timeDuration) => {
  const sec_num = parseInt(timeDuration, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (hours === "00") {
    return minutes + ":" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
};

export const mnths = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export function formatTime(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}
