import React from "react";

export const routeConfig = {
  dashboard: {},
  kadamba: {
    league_list: "league-mapping",
    venue_list: "venue-mapping",
    team_list: "team-mapping",
    match_list: "match-mapping",
    player_list: "player-mapping",
    match_report: "match-report",
  },
  dfs: {
    matches: "matches/:status",
    teams: "teams",
    players: "players",
    match_detail: "match-detail/:id",
    leagues: "leagues",
    league_details: "league-details/:id",
  },
  content: {
    video_list: "video-list",
    video_create: "video-create",
    video_edit: "video-edit/:id",
    video_detail: "video-details/:id",

    package_list: "package-list",
    package_create: "package-create",
    package_edit: "package-edit/:id",
    package_detail: "package-details/:id",

    page_list: "page-list",
    page_create: "page-create",
    page_edit: "page-edit/:id",
    page_detail: "page-details/:id",

    story_list: "story-list",
    story_create: "story-create",
    story_edit: "story-edit/:id",
    story_detail: "story-details/:id",

    livestream_list: "livestream-list",
    livestream_create: "livestream-create",
    livestream_detail: "livestream-details/:id",
    livestream_edit: "livestream-edit/:id",

    blog_list: "blog-list",
    blog_create: "blog-create",
    blog_edit: "blog-edit/:id",
    blog_detail: "blog-details/:id",

    youtube_list: "youtube-list",
    youtube_create: "youtube-create",
    youtube_edit: "youtube-edit/:id",
    youtube_detail: "youtube-details/:id",

    banner_list: "banner-list",
    banner_create: "banner-create",
    banner_edit: "banner-edit/:id",
    banner_detail: "banner-details/:id",
  },
  settings: {
    app_config_list: "app-config-list",
    config_create: "config-create",
    config_edit: "config-edit/:id",
    backend_config_list: "backend-config-list",
    backend_config_edit: "backend-config-edit/:id",
  },
  users: {
    users_overview: "overview",
    manage_users: "manage",
  },
  users_new: {
    users_new_overview: "overview",
    manage_new_users: "manage",
    user_new_detail: "user-detail/:id",
  },
  streaming: {
    stream_list: "stream-list",
    stream_create: "stream-create",
    stream_detail: "stream-details/:id",
    stream_edit: "stream-edit/:id",

    matchstream_list: "matchstream-list",
    matchstream_create: "matchstream-create",
    matchstream_detail: "matchstream-details/:id",
    matchstream_edit: "matchstream-edit/:id",
  },
  assets: {
    images: "images",
    docs: "docs",
    add_image: "add-image",
    add_doc: "add-doc",
    edit_image: "edit-image/:id",
    edit_doc: "edit-doc/:id",
  },
  avatar: {
    avatar_manage: "avatar-overview",
  },
  subscription: {
    master_susbcription: "master-subscripton",
    credit_packages: "credit-packages",
  },
  usq: {
    mentor_module: "mentor-manage",
    student_module: "student-manage",
  },
  messages: {
    notifications: "notifications",
  },
};

export const componentMap = {
  video_list: React.lazy(() => import("../components/video-list")),
  video_create: React.lazy(() => import("../modules/vod-create")),
  video_edit: React.lazy(() => import("../modules/vod-form")),
  video_detail: React.lazy(() => import("../modules/video-detail")),

  story_list: React.lazy(() => import("../components/story-list")),
  story_create: React.lazy(() => import("../modules/story-create")),
  story_edit: React.lazy(() => import("../modules/story-form")),
  story_detail: React.lazy(() => import("../modules/story-detail")),

  package_list: React.lazy(() => import("../components/package-list")),
  package_create: React.lazy(() => import("../modules/package-create")),
  package_edit: React.lazy(() => import("../modules/package-form")),
  package_detail: React.lazy(() => import("../modules/package-detail")),

  page_list: React.lazy(() => import("../components/page-list")),
  page_create: React.lazy(() => import("../modules/page-create")),
  page_edit: React.lazy(() => import("../modules/page-form")),
  page_detail: React.lazy(() => import("../modules/page-detail")),

  league_list: React.lazy(() => import("../components/league-list")),
  venue_list: React.lazy(() => import("../components/venue-list")),
  player_list: React.lazy(() => import("../components/player-list")),
  team_list: React.lazy(() => import("../components/team-list")),
  match_list: React.lazy(() => import("../components/match-list")),
  match_report: React.lazy(() => import("../components/kadamba-match-report")),

  matches: React.lazy(() => import("../components/matches")),
  teams: React.lazy(() => import("../components/teams")),
  players: React.lazy(() => import("../components/players")),
  match_detail: React.lazy(() => import("../components/match-detail")),
  leagues: React.lazy(() => import("../components/leagues")),
  league_details: React.lazy(() => import("../components/leagues/league-details")),

  livestream_list: React.lazy(() => import("../components/livestream-list")),
  livestream_create: React.lazy(() => import("../modules/livestream-create")),
  livestream_edit: React.lazy(() => import("../modules/livestream-form")),
  livestream_detail: React.lazy(() => import("../modules/livestream-detail")),

  app_config_list: React.lazy(() => import("../components/app-config-list")),
  config_create: React.lazy(() => import("../modules/config-create")),
  config_edit: React.lazy(() => import("../modules/config-form")),
  backend_config_list: React.lazy(() => import("../components/backend-config-list")),
  backend_config_edit: React.lazy(() => import("../modules/backend-config-form")),

  blog_list: React.lazy(() => import("../components/blog-list")),
  blog_create: React.lazy(() => import("../modules/blog-create")),
  blog_edit: React.lazy(() => import("../modules/blog-form")),
  blog_detail: React.lazy(() => import("../modules/blog-detail")),

  youtube_list: React.lazy(() => import("../components/youtube-list")),
  youtube_create: React.lazy(() => import("../modules/youtube-create")),
  youtube_edit: React.lazy(() => import("../modules/youtube-form")),
  youtube_detail: React.lazy(() => import("../modules/youtube-detail")),

  banner_list: React.lazy(() => import("../components/banner-list")),
  banner_create: React.lazy(() => import("../modules/banner-create")),
  banner_edit: React.lazy(() => import("../modules/banner-form")),
  banner_detail: React.lazy(() => import("../modules/banner-detail")),

  users_overview: React.lazy(() => import("../components/users-overview")),
  manage_users: React.lazy(() => import("../components/manage-users")),

  users_new_overview: React.lazy(() => import("../components/users-overview-new")),
  manage_new_users: React.lazy(() => import("../components/manage-users-new")),
  user_new_detail: React.lazy(() => import("../modules/user-detail")),

  stream_list: React.lazy(() => import("../components/stream-list")),
  stream_create: React.lazy(() => import("../modules/stream-create")),
  stream_edit: React.lazy(() => import("../modules/stream-form")),
  stream_detail: React.lazy(() => import("../modules/stream-detail")),

  matchstream_list: React.lazy(() => import("../components/matchstream-list")),
  matchstream_create: React.lazy(() => import("../modules/matchstream-create")),
  matchstream_edit: React.lazy(() => import("../modules/matchstream-form")),
  matchstream_detail: React.lazy(() => import("../modules/matchstream-detail")),

  images: React.lazy(() => import("../components/images")),
  docs: React.lazy(() => import("../components/docs")),
  add_image: React.lazy(() => import("../components/create-asset")),
  add_doc: React.lazy(() => import("../components/create-asset")),
  edit_image: React.lazy(() => import("../modules/edit-asset")),
  edit_doc: React.lazy(() => import("../modules/edit-asset")),

  content: React.lazy(() => import("../modules/content")),
  kadamba: React.lazy(() => import("../modules/kadamba")),
  dfs: React.lazy(() => import("../modules/dfs")),
  settings: React.lazy(() => import("../modules/settings")),
  dashboard: React.lazy(() => import("../modules/dashboard")),
  users: React.lazy(() => import("../modules/users")),
  users_new: React.lazy(() => import("../modules/users")),
  streaming: React.lazy(() => import("../modules/streaming")),
  assets: React.lazy(() => import("../modules/assets")),

  avatar: React.lazy(() => import("../modules/avatar")),
  avatar_manage: React.lazy(() => import("../modules/avatar-manage")),

  subscription: React.lazy(() => import("../modules/subscription-manage")),
  master_susbcription: React.lazy(() => import("../modules/subscription-manage/master-subscription")),
  credit_packages: React.lazy(() => import("../modules/subscription-manage/credit-packages")),

  usq: React.lazy(() => import("../modules/USQ")),
  mentor_module: React.lazy(() => import("../modules/USQ/Mentor/Mentor")),
  student_module: React.lazy(() => import("../modules/USQ/Student")),

  messages: React.lazy(() => import("../modules/Messages")),
  notifications: React.lazy(() => import("../modules/Messages/Notification")),
};
