import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Affix, Dropdown, Select, Typography, Space, Button } from "antd";
import { MenuOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { logout } from '../../reducers/loginSlice';
import { toggleSidebar } from '../../reducers/sidebarSlice';
import { changeCurrentSports } from '../../reducers/sportsSlice'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])

  const sportsList = useSelector(state => state.sportsDetails.sportsList)
  const currentSports = useSelector(state => state.sportsDetails.currentSports)

  const items = [

    {
      label: <>
        <img src='/assets/big_logo.png' width='35px' style={{ backgroundColor: 'white', padding: '8px', borderRadius: '8px' }} />
        <MenuOutlined className='menu-icon' style={{ fontSize: '24px', marginLeft: '20px' }} onClick={() => dispatch(toggleSidebar())} />
        <Select
          value={currentSports ? currentSports.name : 'Cricket'}
          style={{ marginLeft: '50px', width: '150px' }}
          className='sports-selector'
          options={options}
          onChange={(val) => {
            dispatch(changeCurrentSports(val))
            navigate('/dashboard')
            window.location.reload()
          }}
        />
      </>
    },

    {
      label: <>
        <Button
          style={{
            backgroundColor: '#2a2e49',
            border: '1px solid white',
            color: 'white'
          }}
          onClick={() => {
            dispatch(logout())
            navigate('/login')
          }}
        >
          Log Out
        </Button>
      </>
    }

  ]

  useEffect(() => {

    if (sportsList.length !== 0) {
      const arr = []

      sportsList.forEach((sports, i) => {

        arr.push({
          value: sports.name,
          label: sports.name === 'Soccer' ? 'FOOTBALL' : sports.name.toUpperCase()
        })

      })

      setOptions(arr)

    }

  }, [sportsList])

  return (
    <>
      <Affix offsetTop={0}>
        <Menu className='menu' items={items} />
      </Affix>
    </>
  )
}

export default Navbar
