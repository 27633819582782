import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const loginSlice = createSlice({
  name: "isLoggedIn",
  initialState: {
    isLoggedIn: false,
    authToken: null,
  },

  reducers: {
    setLogin: (state, action) => {
      if (action.payload.status_code == 200) {
        state.isLoggedIn = true;
        state.authToken = action.payload.auth_token;
      } else {
        message.error("Invalid Crdentials");
      }
    },

    logout: (state, action) => {
      state.isLoggedIn = false;
      state.authToken = null;
    },
  },
});

export const { setLogin, logout } = loginSlice.actions;

export default loginSlice.reducer;
