import axios from "axios";
import { store } from '../store/store';
import { logout } from "../reducers/loginSlice";
import { Navigate } from "react-router-dom";
import { message } from "antd";
import { USQ_ADMIN_API_KEY } from "../constants/constants";

const axiosClient = () => {

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'auth_token': store.getState().isLoggedIn.authToken
        }
    })

    axiosInstance.interceptors.response.use(

        response => {
            return response
        },

        error => {



            if (error.response.status === 401) {

                message.error({
                    content: 'Please login again',
                    className: 'message-container'
                })

                store.dispatch(logout())

                return <Navigate to='/login' />

            }

            throw error

        }

    )

    return axiosInstance

}

const newAxiosClient = () => {

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_VUBASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'auth_token': store.getState().isLoggedIn.authToken
        }
    })

    axiosInstance.interceptors.response.use(

        response => {
            return response
        },

        error => {

            console.log(error)

            if (error.response.status === 401) {

                message.error({
                    content: 'Please login again',
                    className: 'message-container'
                })

                store.dispatch(logout())

                return <Navigate to='/login' />

            }

            throw error

        }

    )

    return axiosInstance

}

const userAxiosClient = () => {

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_VU_USER_URL,
        headers: {
            'Content-Type': 'application/json',
            'auth_token': store.getState().isLoggedIn.authToken
        }
    })

    axiosInstance.interceptors.response.use(

        response => {
            return response
        },

        error => {

            if (error.response.status === 401) {

                message.error({
                    content: 'Please login again',
                    className: 'message-container'
                })

                store.dispatch(logout())

                return <Navigate to='/login' />

            }

            throw error

        }

    )

    return axiosInstance

}


const usqClient = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_USQ_BASE_URL,  // need to use the process.env.USQ_BASE_URL
        headers: {
            'Content-Type': 'application/json',
            'auth_token': USQ_ADMIN_API_KEY
        }
    })

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                message.error({
                    content: 'Please login again',
                    className: 'message-container'
                })
                store.dispatch(logout())
                return <Navigate to='/login' />
            }
            throw error
        }
    )
    return axiosInstance
}


export { axiosClient, newAxiosClient, userAxiosClient, usqClient }

