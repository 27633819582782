import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({

    name: 'alertDetails',

    initialState: {
        display: false,
        message: '',
        type: ''
    },

    reducers: {

        handleAlert: (state, action) => {

            state.display = action.payload.display
            state.message = action.payload.message
            state.type = action.payload.type

        }
            
    }

})

export const { handleAlert } = alertSlice.actions

export default alertSlice.reducer