import {
  BorderOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
  PlaySquareOutlined,
  CheckCircleOutlined,
  FileOutlined,
  WalletOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MessageOutlined,
} from "@ant-design/icons";
import { Image, Tooltip } from "antd";
import { convertSecondsToTimeString } from "../utils/utils";
import { ToTimeFormat } from "../utils/timeUtil";
import { envConfig } from "./env-config";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(tz);

const timeZone = dayjs.tz.guess();

export const ENDPOINTS = {
  login: "/login/loginByEmail",

  fetchVideos: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;

    if (title) {
      endpoint = `/video/getVideos?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/video/getVideos?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },

  fetchPackages: (pageNumber, pageSize, title, sportsId, status, type) => {
    let endpoint;

    if (title && type) {
      endpoint = `/package/getAllPackagesPaginated?page=${pageNumber}&title=${title}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}&type=${type}`;
    } else if (title && !type) {
      endpoint = `/package/getAllPackagesPaginated?page=${pageNumber}&title=${title}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    } else if (!title && type) {
      endpoint = `/package/getAllPackagesPaginated?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}&type=${type}`;
    } else {
      endpoint = `/package/getAllPackagesPaginated?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },

  fetchPages: (name, status, sportsId) => {
    let endpoint;

    if (name) {
      endpoint = `/page/getAllPages?name=${name}&status=${status}&sports_id=${sportsId}`;
    } else {
      endpoint = `/page/getAllPages?status=${status}&sports_id=${sportsId}`;
    }

    return endpoint;
  },

  getVideoDetail: (id) => `/video/getVideo/${id}`,
  getPackageDetail: (id) => `/package/getPackage/${id}`,
  getPageDetail: (id) => `/page/getPage/${id}`,
  fetchPackageContent: (id) => `/package/getPackageContent/${id}`,
  fetchPageContent: `/package/getPackageByPackageIds`,
  updateVod: "/video/updateVod",
  updatePackage: "/package/updatePackage",
  updateRank: "/package/updateRanks",
  addVideoToPlaylist: "/package/linkPackage",
  updatePage: "/page/updatePage",
  createPage: "/page/createPage",
  createPackage: "/package/createPackage",
  createVod: "/video/createVod",
  fetchLeagueList: "/mapping/league/list",
  saveKadambaMapping: "/mapping/league/save",
  fetchSportsList: "/sports/list",

  fetchMatches: (
    id,
    status,
    pageNumber,
    leagueId,
    featured,
    fantasy,
    is_published
  ) => {
    let endpoint;

    if (leagueId) {
      endpoint = `/sports/${id}/match/list/${status}?page=${pageNumber}&league_id=${leagueId}&feature=${featured}&fantasy=${fantasy}&is_published=${is_published}`;
    } else {
      endpoint = `/sports/${id}/match/list/${status}?page=${pageNumber}&feature=${featured}&fantasy=${fantasy}&is_published=${is_published}`;
    }

    return endpoint;
  },

  fetchVenueList: "/mapping/venue/list",
  saveVenueMapping: "/mapping/venue/save",
  fetchTeamList: "/mapping/team/list",
  saveTeamMapping: "/mapping/team/save",
  fetchMatchList: "/mapping/match/list",
  saveMatchMapping: "/mapping/match/save",
  fetchPlayerList: "/mapping/player/list",
  savePlayerMapping: "/mapping/player/save",
  updateMatch: (sportsId, matchId) => `/sports/${sportsId}/match/${matchId}`,
  feedUpdate: (sportsId) => `/feed/pull/${sportsId}`,
  leagueUpdate: (sportsId) => `/feed/pull-league/${sportsId}`,
  monkData: `sport-monks/pull-team-players-match`,
  fetchTeams: (sportsId, pageNumber, teamName, leagueId) => {
    let endpoint;

    if (teamName && leagueId) {
      endpoint = `/sports/${sportsId}/team?page=${pageNumber}&name=${teamName}&league_id=${leagueId}`;
    } else if (teamName && !leagueId) {
      endpoint = `/sports/${sportsId}/team?page=${pageNumber}&name=${teamName}`;
    } else if (!teamName && leagueId) {
      endpoint = `/sports/${sportsId}/team?page=${pageNumber}&league_id=${leagueId}`;
    } else if (!teamName && !leagueId) {
      endpoint = `/sports/${sportsId}/team?page=${pageNumber}`;
    }

    return endpoint;
  },

  updateTeam: (sportsId, teamId) => `/sports/${sportsId}/team/${teamId}`,
  fetchSportsLeague: (sportsId) => `/sports/${sportsId}/league`,
  fetchPlayers: (sportsId, pageNumber, playerName, teamId) => {
    let endpoint;

    if (playerName && teamId) {
      endpoint = `/sports/${sportsId}/player?page=${pageNumber}&team_id=${teamId}&name=${playerName}`;
    } else if (playerName && !teamId) {
      endpoint = `/sports/${sportsId}/player?page=${pageNumber}&name=${playerName}`;
    } else if (!playerName && teamId) {
      endpoint = `/sports/${sportsId}/player?page=${pageNumber}&team_id=${teamId}`;
    } else if (!playerName && !teamId) {
      endpoint = `/sports/${sportsId}/player?page=${pageNumber}`;
    }

    return endpoint;
  },
  updatePlayer: (sportsId, playerId) =>
    `/sports/${sportsId}/player/${playerId}`,
  createStory: "/story/createStory",
  updateStory: "/story/updateStory",
  getStoryDetail: (id) => `/story/getStory/${id}`,
  fetchStories: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;

    if (title) {
      endpoint = `/story/getAllStories?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/story/getAllStories?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },

  deleteStory: "/story/deleteStory",
  deleteVideo: "/video/deleteVod",
  deletePackage: "/package/deletePackage",
  deletePage: "/page/deletePage",
  linkVideoToMatch: "/linkMatchLeagueVideo",
  unlinkVideoToMatch: "/unlinkMatchLeagueVideo",
  getMatchVideos: (matchId, leagueId) => {
    let endpoint;

    if (matchId && leagueId) {
      endpoint = `getMapMatchLeagueVideo?match_id=${matchId}&league_id=${leagueId}`;
    } else if (matchId && !leagueId) {
      endpoint = `getMapMatchLeagueVideo?match_id=${matchId}`;
    } else if (!matchId && leagueId) {
      endpoint = `getMapMatchLeagueVideo?league_id=${leagueId}`;
    }

    return endpoint;
  },
  clearCache: "/clearCache",
  fetchSquad: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/squad`,
  bulkUpload: "/story/createStoryBulk",
  updateStatus: "/updateStatus",
  updatePlaying: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/playing11`,
  updateFantasy: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/fantasyteam`,
  publishFantasy: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/fantasyteam/publish`,
  createLiveStream: `/livestream/createLiveStreams`,
  updateLiveStream: `/livestream/updateLiveStream`,
  getLiveStreamDetail: (streamId) => `/livestream/getLiveStream/${streamId}`,
  fetchLiveStreams: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;

    if (title) {
      endpoint = `/livestream/getLiveStreams?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/livestream/getLiveStreams?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },
  deleteLiveStream: `/livestream/deleteLiveStream`,
  fetchAudios: `https://d3dwzdnjfauts6.cloudfront.net/audio/music-config.json`,
  fetchBlogDetails: `https://helper.vusport.com/vu-admin/v1/get-meta-info`,
  fetchAppConfigs: (pageNumber) => `/app-config?page=${pageNumber}`,
  fetchAppConfigDetail: (configId) => `/app-config/${configId}`,
  createAppConfig: `/app-config`,
  updateAppConfig: (configId) => `/app-config/${configId}`,
  fetchMappedLeagues: "/mapping/allMapLeagues",
  fetchBlogs: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;

    if (title) {
      endpoint = `/blog/getAll?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/blog/getAll?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },
  getBlogDetail: (blogId) => `/blog/get/${blogId}`,
  updateBlog: `/blog/update`,
  deleteBlog: `/blog/delete`,
  createBlog: `/blog/create`,
  fetchYoutubeVideos: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;

    if (title) {
      endpoint = `/youtube/getAllYoutube?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/youtube/getAllYoutube?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },
  getYoutubeVideoDetail: (youtubeVideoId) =>
    `/youtube/getYoutube/${youtubeVideoId}`,
  updateYoutubeVideo: "/youtube/updateYoutube",
  deleteYoutubeVideo: "/youtube/deleteYoutube",
  createYoutubeVideo: "/youtube/createYoutube",
  pullFantasy: (sportsId, matchId) =>
    `/sports/${sportsId}/fantasyteam/pull?match_id=${matchId}`,
  pullFantasyGL: (sportsId, matchId) =>
    `/sports/${sportsId}/glfantasyteam/pull?match_id=${matchId}`,
  getFantasyLogs: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/fantasyteam/logs`,
  createBanner: "/banner/create",
  deleteBanner: "/banner/delete",
  fetchBanners: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;

    if (title) {
      endpoint = `/banner/getAll?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/banner/getAll?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }

    return endpoint;
  },
  getBannerDetail: (bannerId) => `/banner/get/${bannerId}`,
  updateBanner: "/banner/update",
  fetchUsersDump: "/user",
  fetchUsersNewDump: "/user/list/download",
  fetchUsersDumpCount: (startDate, endDate) => {
    let endpoint;
    if (startDate && endDate) {
      endpoint = `/user/count?start_date=${startDate}&end_date=${endDate}`;
    } else {
      endpoint = `/user/count`;
    }
    return endpoint;
  },
  fetchVuLeadsDump: (startDate, endDate) => {
    let endpoint;
    if (startDate && endDate) {
      endpoint = `/leads/download?start_date=${startDate}&end_date=${endDate}`;
    } else {
      endpoint = `/leads/download`;
    }
    return endpoint;
  },

  createStream: `/stream/create`,
  updateStream: `/stream/update`,
  getStreamDetail: (streamingId) => `/stream/get/${streamingId}`,
  fetchAllStreams: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;
    if (title) {
      endpoint = `/stream/getAll?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/stream/getAll?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }
    return endpoint;
  },
  deleteStream: `/stream/delete`,
  unblockUser: (phoneNumber, countryCode) =>
    `/user/unblock?phoneNo=${phoneNumber}&countryCode=${countryCode}`,
  getMatchDetail: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/detail`,
  createMatchstream: `/match-stream/create`,
  updateMatchstream: `/match-stream/update`,
  getMatchstreamDetail: (streamingId) => `/match-stream/get/${streamingId}`,
  fetchAllMatchstreams: (pageNumber, pageSize, title, sportsId, status) => {
    let endpoint;
    if (title) {
      endpoint = `/match-stream/getAll?page=${pageNumber}&page_size=${pageSize}&title=${title}&sports_id=${sportsId}&status=${status}`;
    } else {
      endpoint = `/match-stream/getAll?page=${pageNumber}&page_size=${pageSize}&sports_id=${sportsId}&status=${status}`;
    }
    return endpoint;
  },
  deleteMatchstream: `/match-stream/delete`,
  updateSchedule: `/package/updatePackageItem`,
  getFantasyteamDetail: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/fantasyteam/detail`,
  changePublishTime: (sportsId, matchId) =>
    `/sports/${sportsId}/match/${matchId}/fantasyteam/changePublishTime`,
  linkHighlights: "/highlights/link",
  unlinkHighlights: "/highlights/unlink",
  getHighlights: (matchId) => `/highlights/getAll?match_id=${matchId}`,
  fetchUsers: (pageNumber, pageSize, searchType, searchQuery) => {
    let endpoint;

    if (searchType === "phone") {
      endpoint = `/user/list?page=${pageNumber}&page_size=${pageSize}&phone=${searchQuery}`;
    } else if (searchType === "email") {
      endpoint = `/user/list?page=${pageNumber}&page_size=${pageSize}&email=${searchQuery}`;
    }

    return endpoint;
  },
  updateUserBlockStatus: "/user/blockStatus",
  fetchUserProfileList: (userId) => `/user/profile/list?user_id=${userId}`,
  fetchUserDeviceList: (userId) => `/user/device/list?user_id=${userId}`,
  fetchSettingList: "/setting/list",
  updateSetting: "/setting",
  getkadambaMatchReport: (endDate, startDate) =>
    `/kadamba/matchReport?toDate=${endDate}&fromDate=${startDate}`,
  createAsset: `/assets`,
  assetsList: (pageNumber, type, pageSize) =>
    `/assets?page=${pageNumber}&type=${type}&page_size=${pageSize}`,
  getAsset: (assetId) => `/assets/${assetId}`,
  updateAsset: `/assets`,
  deleteAsset: `/assets`,
  createFantasyTip: "/fantasy-tips",
  updateFantasyTip: "/fantasy-tips",
  getFantasyTip: (matchId, leagueId, sportsId) =>
    `/fantasy-tips?match_id=${matchId}&league_id=${leagueId}&sports_id=${sportsId}`,
  deleteFantasyTip: "/fantasy-tips",
  masterSubscription: "master-subscription",
  getMasterSubscription: (type) => "master-subscription/list?tab=" + type,
  toggleMasterSubs: "master-subscription/active-inactive",
  matchTabs: "/match-tabs",
  getMatchTabs: (m_id) => `/match-tabs?match_id=${m_id}`,
  getSubscritionList: (typeId, type) =>
    `/subscription/list?type_id=${typeId}&type=${type}`,
  adminSubscription: "/subscription",
  toggleSubscriptionStatus: "/subscription/active-inactive",
  getTransactions: (user_id, page, page_size) =>
    `/transaction/list?user_id=${user_id}&page=${page}&page_size=${page_size}`,
  getTrxnWithFilters: (userId, page, pagesize, startDate, endDate) =>
    `/transaction/list?user_id=${userId}&page=${page}&page_size=${pagesize}&start_date=${startDate}&end_date=${endDate}`,
  creditPackages: `/credit-packages`,
  deleteCreditPackage: (id) => `credit-packages?id=${id}`,
  userWallet: (userId) => `/user/wallet?user_id=${userId}`,
  giftCredit: "user/gift-card",
  userOrderFilter: (user_id, page_size, page, type, type_id) =>
    `/order/list?user_id=${user_id}&type=${type}&type_id=${type_id}&page=${page}&page_size=${page_size}`,
  addUserWallet: "/user/wallet/add",
  fetchAssociateList: "associate/list",
  verifyAssociate: "associate/verification",
  downloadAllAssociates: (status) =>
    `download-allteam/list?verification_status=${status}`,
  fetchStudentList: "students/list",
  matchF11Config: (matchId) => `/match-f11-config/${matchId}`,
  updateMatchF11Config: "/match-f11-config",
  fetchF11BaseConfig:
    "https://d1p7lcmzq11lg1.cloudfront.net/app-config/f11_base_config.json",
  downloadAllStudentList: "download-allstudent/list",
  purchaseTrialSubscription: "trial-master-subscription/single-user/purchase",
  getTrailOptions: `${envConfig.TRIAL_OPTION_BASE_URI}app-config/trial_pack_dropdown.json`,
  getPlayerPoints: (matchId) => `/fp/get-player-points?match_id=${matchId}`,
  updateUserPoints: "match/updateUserPoints",
  getNotificationsTemplates: "/notifications/get-payloads",
  deleteNotificationTemplate: "/notifications/delete-payload",
  editNotificationTemplate: "/notifications/update-payload",
  createNotificationTemplate: "/notifications/create-new-payload",
  broadCastNotification: "/notifications/broadcast",
  bulkUploadNotification: "/notifications/bulk-upload",
  clearMatchUpCache: "/match-up/clear-cache",
  validateTxn: (t_id) =>
    `/transaction/verify?type=transaction&transaction_id=${t_id}`,
  validateOrder: (o_id) => `/transaction/verify?type=order&order_id=${o_id}`,
  leagueUpdatePlayerPosition: "/league/update_player",
};

export const railTypes = [
  {
    value: "banner",
    label: "Banner",
  },
  {
    value: "story",
    label: "Story",
  },
  {
    value: "featured_matches",
    label: "Featured Matches",
  },
  {
    value: "scout_content",
    label: "Scout Content",
  },
  {
    value: "featured_leagues",
    label: "Featured Leagues",
  },
  {
    value: "live_content",
    label: "Live Content",
  },
  {
    value: "youtube_content",
    label: "Youtube Content",
  },
  {
    value: "image",
    label: "Upcoming",
  },
  {
    value: "vusport_x_banner",
    label: "VuSport-X Banner",
  },
];

export const sportsMap = {
  1: "cricket",
  2: "football",
};

export const s3ConfigVideo = {
  VIDEO_BASE: "videos/raw-vod",
  THUMBNAIL_BASE: "thumbnails/raw-vod-thumbnails",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}raw-vod-thumbnails/`,
  VIDEO_BASE_URI: `${envConfig.VIDEO_BASE_URI}`,
};

export const s3ConfigAsset = {
  DOC_BASE: "thumbnails/asset/doc",
  IMAGE_BASE: "thumbnails/asset/image",
  IMAGE_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}asset/image/`,
  DOC_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}asset/doc/`,
};

export const s3ConfigStory = {
  VIDEO_BASE: "videos/raw-story",
  THUMBNAIL_BASE: "thumbnails/raw-story-thumbnails",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}raw-story-thumbnails/`,
  VIDEO_BASE_URI: `${envConfig.STORY_BASE_URI}`,
};

export const s3ConfigStream = {
  THUMBNAIL_BASE: "thumbnails/raw-stream-thumbnails",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}raw-stream-thumbnails/`,
};

export const s3ConfigPackage = {
  THUMBNAIL_BASE: "thumbnails/raw-package-thumbnails",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}raw-package-thumbnails/`,
};

export const s3ConfigPlayer = {
  THUMBNAIL_BASE: "thumbnails/players",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}players/`,
};

export const s3ConfigPlayerOptaId = {
  THUMBNAIL_BASE: "thumbnails/optaid-players",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}players/`,
};

export const s3ConfigTeam = {
  THUMBNAIL_BASE: "thumbnails/teams/logo",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}teams/logo/`,
};

export const s3ConfigBulkUpload = {
  THUMBNAIL_BASE: "thumbnails/bulk",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}bulk/`,
};

export const s3ConfigLeague = {
  THUMBNAIL_BASE: "thumbnails/leagues",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}leagues/`,
};

export const s3ConfigBlog = {
  THUMBNAIL_BASE: "thumbnails/blog_image",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}blog_image/`,
  FILE_BASE: "thumbnails/blog-content",
  FILE_BASE_URI: `${envConfig.FILE_BASE_URI}blog/`,
  PREVIEW_FILE_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}blog-content/`,
};

export const s3ConfigYoutube = {
  THUMBNAIL_BASE: "thumbnails/raw-youtube-thumbnails",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}raw-youtube-thumbnails/`,
};

export const s3ConfigBanner = {
  THUMBNAIL_BASE: "thumbnails/raw-banner-thumbnails",
  THUMBNAIL_BASE_URI: `${envConfig.THUMBNAIL_BASE_URI}raw-banner-thumbnails/`,
};

export const s3ConfigImage = {
  IMAGE_BASE_URL: "https://d1r7hquvd58dz2.cloudfront.net",
};

export const iconMaps = {
  dashboard: <DashboardOutlined style={{ fontSize: "20px" }} />,
  kadamba: <DatabaseOutlined style={{ fontSize: "20px" }} />,
  dfs: <HomeOutlined style={{ fontSize: "20px" }} />,
  content: <BorderOutlined style={{ fontSize: "20px" }} />,
  settings: <SettingOutlined style={{ fontSize: "20px" }} />,
  users: <UserOutlined style={{ fontSize: "20px" }} />,
  users_new: <UserOutlined style={{ fontSize: "20px" }} />,
  streaming: <PlaySquareOutlined style={{ fontSize: "20px" }} />,
  assets: <FileOutlined style={{ fontSize: "20px" }} />,
  avatar: <UserOutlined style={{ fontSize: "20px" }} />,
  subscription: <WalletOutlined style={{ fontSize: "20px" }} />,
  usq: <SettingOutlined style={{ fontSize: "20px" }} />,
  messages: <MessageOutlined style={{ fontSize: "20px" }} />,
};

export const capitalizeFirstLetter = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
};

export const audioOptions = [
  {
    value:
      "https://cdn.bitmovin.com/content/assets/art-of-motion-dash-hls-progressive/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa-audio-only.m3u8",
    label: "Background Music",
  },
];

export const packageTypes = [
  {
    value: "video_detail",
    label: "Video",
  },
  {
    value: "story",
    label: "Story",
  },
  {
    value: "package",
    label: "Package",
  },
  {
    value: "livestreams",
    label: "Livestream",
  },
  {
    value: "youtube",
    label: "Youtube",
  },
  {
    value: "banner",
    label: "Banner",
  },
];

export const videoColumns = [
  {
    title: "Video Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Duration",
    dataIndex: "duration",
    align: "center",
    key: "duration",
    render: (duration) => {
      return <span>{convertSecondsToTimeString(duration)}</span>;
    },
  },

  {
    title: "Raw Url",
    dataIndex: "raw_url",
    align: "center",
    key: "duration",
    hidden: true,
  },

  {
    title: "Subscription",
    dataIndex: "subscription_id",
    align: "center",
    key: "subscription_id",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Searchable",
    dataIndex: "is_searchable",
    align: "center",
    key: "is_searchable",
    render: (isSearchable) => <span>{isSearchable == 1 ? "No" : "Yes"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },

  {
    title: "Lmv Id",
    key: "lmv_id",
    align: "center",
    dataIndex: "lmv_id",
    hidden: true,
  },
].filter((item) => !item.hidden);

export const storyColumns = [
  {
    title: "Story Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    key: "type",
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },

  {
    title: "Url",
    dataIndex: "url",
    align: "center",
    key: "url",
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const livestreamColumns = [
  {
    title: "Stream Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },

  {
    title: "Fallback",
    dataIndex: "fallback",
    align: "center",
    key: "fallback",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Url",
    dataIndex: "url",
    align: "center",
    key: "url",
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const matchstreamColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Base Url",
    dataIndex: "url",
    align: "center",
    key: "base_url",
  },

  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    key: "type",
  },

  {
    title: "Type Id",
    dataIndex: "type_id",
    align: "center",
    key: "type_id",
  },

  {
    title: "League Id",
    dataIndex: "league_id",
    align: "center",
    key: "league_id",
  },

  {
    title: "Start Time",
    dataIndex: "start_time",
    align: "center",
    key: "start_time",
    render: (startDate) => (
      <span>{dayjs.tz(startDate, timeZone).format("DD MMM YYYY hh:mm A")}</span>
    ),
  },

  {
    title: "End Time",
    dataIndex: "expected_end_time",
    align: "center",
    key: "expected_end_time",
    render: (endDate) => (
      <span>{dayjs.tz(endDate, timeZone).format("DD MMM YYYY hh:mm A")}</span>
    ),
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const packageColumns = [
  {
    title: "Package Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Subscription Id",
    dataIndex: "subscription_id",
    align: "center",
    key: "subscription_id",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    key: "type",
    render: (type) => <span>{capitalizeFirstLetter(type.split("_")[0])}</span>,
  },

  {
    title: "Date Check",
    dataIndex: "date_check",
    align: "center",
    key: "date_check",
    render: (dateCheck) => <span>{dateCheck == 1 ? "Yes" : "No"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const pageColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    key: "name",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (id) => <span>{id === 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },

  {
    title: "Subscription",
    dataIndex: "subscription_id",
    align: "center",
    key: "subscription_id",
  },
].filter((item) => !item.hidden);

export const trophyImages = [
  {
    label: "Premier League",
    value:
      "https://raw.githubusercontent.com/shobhit-sssg/test/main/epl_logo.png",
  },
  {
    label: "Champions League",
    value:
      "https://raw.githubusercontent.com/shobhit-sssg/test/main/UEFA champions league.png",
  },
  {
    label: "Indian Super League",
    value: "https://raw.githubusercontent.com/shobhit-sssg/test/main/ISL.png",
  },
  {
    label: "Serie A",
    value:
      "https://raw.githubusercontent.com/shobhit-sssg/test/main/Italian Serie A.png",
  },
  {
    label: "La Liga",
    value:
      "https://raw.githubusercontent.com/shobhit-sssg/test/main/Spanish La Liga.png",
  },
  {
    label: "Bundesliga",
    value:
      "https://raw.githubusercontent.com/shobhit-sssg/test/main/German Bundesliga.png",
  },
  {
    label: "Ligue 1",
    value:
      "https://raw.githubusercontent.com/shobhit-sssg/test/main/French Ligue 1.png",
  },
  {
    label: "General",
    value: "",
  },
];

export const hardCodedLeagueJson = [
  {
    league_id: "4549",
    name: "Premier League",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/epl_logo.png",
  },
  {
    league_id: "4577",
    name: "UCL",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/UEFA champions league.png",
  },
  {
    league_id: "4654",
    name: "Indian Super League",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/ISL.png",
  },
  {
    league_id: "4664",
    name: "Serie A",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/Italian Serie A.png",
  },
  {
    league_id: "4877",
    name: "La Liga",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/Spanish La Liga.png",
  },
  {
    league_id: "4625",
    name: "Bundesliga",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/German Bundesliga.png",
  },
  {
    league_id: "4614",
    name: "Ligue 1",
    url: "https://raw.githubusercontent.com/shobhit-sssg/test/main/French Ligue 1.png",
  },
];

export const leagueColumns = [
  {
    title: "League Id",
    dataIndex: "league_id",
    align: "center",
    key: "league_id",
  },

  {
    title: "League Name",
    dataIndex: "name",
    align: "center",
    key: "name",
  },

  {
    title: "Logo",
    dataIndex: "url",
    align: "center",
    key: "url",
    render: (url) => (
      <Image
        width={50}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },
];

export const pageFormColumns = [
  {
    title: "Package Id",
    dataIndex: "package_id",
    align: "center",
    key: "package_id",
  },

  {
    title: "Package Name",
    dataIndex: "package_name",
    align: "center",
    key: "package_name",
  },

  {
    title: "Title",
    dataIndex: "package_title",
    align: "center",
    key: "title",
  },

  {
    title: "Subtitle",
    dataIndex: "package_subtitle",
    align: "center",
    key: "subtitle",
  },

  {
    title: "Dynamic",
    dataIndex: "is_dynamic",
    align: "center",
    key: "dynamic",
  },

  {
    title: "Rail Type",
    dataIndex: "rail_type",
    align: "center",
    key: "rail_type",
    render: (rail_type) => {
      const arr = rail_type.split("_");

      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      const str = arr.join(" ");

      return <span>{str}</span>;
    },
  },
];

export const blogColumns = [
  {
    title: "Blog Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => <Image width={100} src={url} />,
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Url",
    dataIndex: "url",
    align: "center",
    key: "duration",
    hidden: true,
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const youtubeVideosColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => <Image width={100} src={url} />,
  },
  {
    title: "Duration",
    dataIndex: "duration",
    align: "center",
    key: "thumbnail",

    render: (duration) => <span>{duration ? ToTimeFormat(duration) : 0}</span>,
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Youtube Id",
    dataIndex: "url",
    align: "center",
    key: "duration",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const bannerColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => <Image width={100} src={url} />,
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Cta",
    dataIndex: "cta",
    align: "center",
    key: "cta",
  },

  {
    title: "Url",
    dataIndex: "url",
    align: "center",
    key: "duration",
  },

  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    key: "type",
    render: (type) => <span>{type === "potrait_video" ? "story" : type}</span>,
  },

  {
    title: "Type Id",
    dataIndex: "type_id",
    align: "center",
    key: "type_id",
  },

  {
    title: "League Id",
    dataIndex: "league_id",
    align: "center",
    key: "league_id",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const fantasyLogsColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
    hidden: true,
  },

  {
    title: "Created At",
    dataIndex: "created_at",
    align: "center",
    key: "created_at",
  },

  {
    title: "Process Error",
    dataIndex: "process_error",
    align: "center",
    key: "process_error",
    ellipsis: true,
    render: (processError) => (
      <Tooltip placement="topLeft" title={processError}>
        {processError}
      </Tooltip>
    ),
  },
  {
    title: "Team Type",
    dataIndex: "team_type",
    align: "center",
    key: "team_type",
    ellipsis: true,
    render: (teamType) => (
      <Tooltip placement="topLeft" title={"team_type"}>
        {teamType == 2 ? "GL" : "fantasy"}
      </Tooltip>
    ),
  },

  {
    title: "Request Payload",
    dataIndex: "request_payload",
    align: "center",
    key: "request_payload",
    ellipsis: true,
  },

  {
    title: "Response",
    dataIndex: "response",
    align: "center",
    key: "response",
    ellipsis: true,
  },

  {
    title: "Http Code",
    dataIndex: "response_http_code",
    align: "center",
    key: "response_http_code",
  },
].filter((item) => !item.hidden);

export const streamColumns = [
  {
    title: "Streaming Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Base Url",
    dataIndex: "base_url",
    align: "center",
    key: "base_url",
  },

  {
    title: "Rtmp Url",
    dataIndex: "rtmp_url",
    align: "center",
    key: "rtmp_url",
  },

  {
    title: "Sports",
    dataIndex: "sports_id",
    align: "center",
    key: "sports_id",
    render: (sportsId) => <span>{sportsId == 1 ? "Cricket" : "Football"}</span>,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    key: "status",
  },
].filter((item) => !item.hidden);

export const tableScroll = {};

export const highlightsColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
    hidden: true,
  },

  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
    align: "center",
    key: "thumbnail",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },

  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    key: "type",
  },

  {
    title: "Type Id",
    dataIndex: "type_id",
    align: "center",
    key: "type_id",
  },

  {
    title: "Title",
    dataIndex: "title",
    align: "center",
    key: "title",
  },

  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    key: "description",
  },

  {
    title: "Duration",
    dataIndex: "duration",
    align: "center",
    key: "duration",
    render: (duration) => {
      return <span>{convertSecondsToTimeString(duration)}</span>;
    },
  },

  {
    title: "Url",
    dataIndex: "url",
    align: "center",
    key: "url",
  },
].filter((item) => !item.hidden);

export const usersColumns = [
  {
    title: "User Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Name",
    dataIndex: "f_name",
    align: "center",
    key: "name",
  },

  {
    title: "Country",
    dataIndex: "country",
    align: "center",
    key: "country",
    // hidden: true
  },

  {
    title: "Phone",
    dataIndex: "phone",
    align: "center",
    key: "phone",
    render: (phone, record) => {
      return (
        <span
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "8px" }}
        >
          <p className="mb-0">
            +{record.country_code} {phone}
          </p>
          {record.verification_status.phone && (
            <CheckCircleOutlined
              style={{ color: "#19caa8", fontWeight: 900 }}
            />
          )}
        </span>
      );
    },
  },

  {
    title: "Gift Credits",
    dataIndex: "gift_credits",
    align: "center",
    key: "gift_credits",
  },

  {
    title: "Credit Enable",
    dataIndex: "is_popup",
    align: "center",
    key: "is_popup",
    render: (is_popup) => {
      return (
        <>
          {!is_popup ? (
            <CheckCircleTwoTone title="Enabled" twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone title="Disabled" twoToneColor="red" />
          )}
        </>
      );
    },
  },

  {
    title: "Email",
    dataIndex: "email",
    align: "center",
    key: "email",
    render: (email, record) => {
      return (
        <span
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "8px" }}
        >
          <p className="mb-0">{email}</p>
          {record.verification_status.email && (
            <CheckCircleOutlined
              style={{ color: "#19caa8", fontWeight: 900 }}
            />
          )}
        </span>
      );
    },
  },
].filter((item) => !item.hidden);

export const userProfileColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "User Id",
    dataIndex: "user_id",
    align: "center",
    key: "user_id",
    hidden: true,
  },

  {
    title: "Name",
    dataIndex: "user_name",
    align: "center",
    key: "user_name",
  },

  {
    title: "Created At",
    dataIndex: "created_at",
    align: "center",
    key: "created_at",
    render: (createdAt) => {
      if (createdAt) {
        return (
          <p className="mb-0">
            {dayjs
              .tz(createdAt, timeZone)
              .add(330, "minute")
              .format("D MMM YYYY hh:mm:ss A")}
          </p>
        );
      } else {
        return <></>;
      }
    },
  },

  {
    title: "Updated At",
    dataIndex: "updated_at",
    align: "center",
    key: "updated_at",
    render: (updatedAt) => {
      if (updatedAt) {
        return (
          <p className="mb-0">
            {dayjs
              .tz(updatedAt, timeZone)
              .add(330, "minute")
              .format("D MMM YYYY hh:mm:ss A")}
          </p>
        );
      } else {
        <></>;
      }
    },
  },

  {
    title: "Deleted At",
    dataIndex: "deleted_at",
    align: "center",
    key: "deleted_at",
    render: (deletedAt) => {
      if (deletedAt) {
        <p className="mb-0">
          {dayjs
            .tz(deletedAt, timeZone)
            .add(330, "minute")
            .format("D MMM YYYY hh:mm:ss A")}
        </p>;
      } else {
        <></>;
      }
    },
  },
].filter((item) => !item.hidden);

export const userDeviceColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
    key: "id",
  },

  {
    title: "Type",
    dataIndex: "device_type",
    align: "center",
    key: "device_type",
  },

  {
    title: "Uid",
    dataIndex: "device_uid",
    align: "center",
    key: "device_uid",
  },

  {
    title: "App Version",
    dataIndex: "app_v",
    align: "center",
    key: "app_v",
  },

  {
    title: "OS",
    dataIndex: "os",
    align: "center",
    key: "os",
  },

  {
    title: "Created At",
    dataIndex: "created_at",
    align: "center",
    key: "created_at",
    render: (createdAt) => {
      if (createdAt) {
        return (
          <p className="mb-0">
            {dayjs
              .tz(createdAt, timeZone)
              .add(330, "minute")
              .format("D MMM YYYY hh:mm:ss A")}
          </p>
        );
      } else {
        return <></>;
      }
    },
  },
].filter((item) => !item.hidden);

export const imageAssetsColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Title",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    align: "center",
  },
  {
    title: "Url",
    dataIndex: "url",
    align: "center",
    hidden: true,
  },
  {
    title: "Image",
    dataIndex: "url",
    align: "center",
    render: (url) => (
      <Image
        width={100}
        src={url}
        onClick={(event) => event.stopPropagation()}
      />
    ),
  },
].filter((item) => !item.hidden);

export const docAssetsColumns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Title",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    align: "center",
  },
  {
    title: "Url",
    dataIndex: "url",
    align: "center",
  },
].filter((item) => !item.hidden);

export const USQ_ADMIN_API_KEY = "fb16f55bc53bd23c30b2d57befc4d0a0";
