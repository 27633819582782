import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Menu, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
import { toggleSidebar } from "../../reducers/sidebarSlice";
import { selectShowSidebar } from "../../selectors/sidebar";
import { iconMaps } from "../../constants/constants";

const { Title } = Typography;

const Sidebar = () => {
  const sportsId = useSelector(
    (state) => state.sportsDetails?.currentSports?.id
  );

  const [items, setItems] = useState([]);

  const location = useLocation();
  const dispatch = useDispatch();

  const showSidebar = useSelector(selectShowSidebar);

  const fetchItemsHandler = (pathname) => {
    const modules = {
      dashboard: 1,
      content: 1,
      streaming: 0,
      kadamba: sportsId == 1 ? 1 : 0,
      dfs: 1,
      settings: 1,
      users: 0,
      users_new: 1,
      assets: 1,
      avatar: 1,
      subscription: 1,
      usq: 1,
      messages: 1
    };

    const drawerItems = [];

    for (const [key, val] of Object.entries(modules)) {
      // console.log(pathname)

      let item;

      if (val === 1) {
        let children;

        if (key === "content") {
          children = [
            {
              key: "Videos",
              label: (
                <Link to={`${key}/video-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/video-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Videos
                  </Title>
                </Link>
              ),
            },

            {
              key: "Stories",
              label: (
                <Link to={`${key}/story-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/story-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Stories
                  </Title>
                </Link>
              ),
            },

            {
              key: "Blogs",
              label: (
                <Link to={`${key}/blog-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/blog-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Blogs
                  </Title>
                </Link>
              ),
            },

            {
              key: "Youtubes",
              label: (
                <Link to={`${key}/youtube-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/youtube-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Youtube
                  </Title>
                </Link>
              ),
            },

            {
              key: "Livestream",
              label: (
                <Link to={`${key}/livestream-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/livestream-list`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Livestreams
                  </Title>
                </Link>
              ),
            },

            {
              key: "Banner",
              label: (
                <Link to={`${key}/banner-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/banner-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Banners
                  </Title>
                </Link>
              ),
            },

            {
              key: "Packages",
              label: (
                <Link to={`${key}/package-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/package-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Packages
                  </Title>
                </Link>
              ),
            },

            {
              key: "Pages",
              label: (
                <Link to={`${key}/page-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/page-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Pages
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "kadamba") {
          children = [
            {
              key: "League Mapping",
              label: (
                <Link to={`${key}/league-mapping`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/league-mapping`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Leagues
                  </Title>
                </Link>
              ),
            },

            {
              key: "Venue Mapping",
              label: (
                <Link to={`${key}/venue-mapping`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/venue-mapping`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Venues
                  </Title>
                </Link>
              ),
            },

            {
              key: "Team Mapping",
              label: (
                <Link to={`${key}/team-mapping`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/team-mapping`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Teams
                  </Title>
                </Link>
              ),
            },

            {
              key: "Player Mapping",
              label: (
                <Link to={`${key}/player-mapping`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/player-mapping`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Players
                  </Title>
                </Link>
              ),
            },

            {
              key: "Match Report",
              label: (
                <Link to={`${key}/match-report`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/match-report`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Match Report
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "dfs") {
          children = [
            {
              key: "Matches",
              label: (
                <Link to={`${key}/matches/live?page=1&is_published=true`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/matches/`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Matches
                  </Title>
                </Link>
              ),
            },

            {
              key: "Teams",
              label: (
                <Link to={`${key}/teams`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/teams`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Teams
                  </Title>
                </Link>
              ),
            },

            {
              key: "Players",
              label: (
                <Link to={`${key}/players`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/players`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Players
                  </Title>
                </Link>
              ),
            },
            {
              key: "Leagues",
              label: (
                <Link to={`${key}/leagues`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/leagues`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Leagues
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "settings") {
          children = [
            {
              key: "App Config",
              label: (
                <Link to={`${key}/app-config-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/app-config-list`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    App Config
                  </Title>
                </Link>
              ),
            },

            {
              key: "Backend Config List",
              label: (
                <Link to={`${key}/backend-config-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/backend-config-list`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Backend Config
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "users") {
          children = [
            {
              key: "Overview",
              label: (
                <Link to={`${key}/overview`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.match(`${key}/overview`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Overview
                  </Title>
                </Link>
              ),
            },

            {
              key: "Manage Users",
              label: (
                <Link to={`${key}/manage`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.match(`${key}/manage`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Manage
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "users_new") {
          children = [
            {
              key: "Overview New",
              label: (
                <Link to={`${key}/overview`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.match(`${key}/overview`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Overview
                  </Title>
                </Link>
              ),
            },

            {
              key: "Manage Users New",
              label: (
                <Link to={`${key}/manage`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.match(`${key}/manage`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Manage
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "streaming") {
          children = [
            {
              key: "Streams",
              label: (
                <Link to={`${key}/stream-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/stream-list`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Streams
                  </Title>
                </Link>
              ),
            },

            {
              key: "Matchstreams",
              label: (
                <Link to={`${key}/matchstream-list`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/matchstream-list`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Matchstreams
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "assets") {
          children = [
            {
              key: "images",
              label: (
                <Link to={`${key}/images`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/images`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Images
                  </Title>
                </Link>
              ),
            },

            {
              key: "docs",
              label: (
                <Link to={`${key}/docs`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/docs`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Docs
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "avatar") {
          children = [
            {
              key: "Avatar overview",
              label: (
                <Link to={`${key}/avatar-overview`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/avatar-overview`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Overview
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "subscription") {
          children = [
            {
              key: "master-subs",
              label: (
                <Link to={`${key}/master-subscripton`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/master-subscripton`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Master Subscription
                  </Title>
                </Link>
              ),
            },
            {
              key: "credit-packages",
              label: (
                <Link to={`${key}/credit-packages`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(
                        `${key}/credit-packages`
                      )
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Credit Packages
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "usq") {
          children = [
            {
              key: "mentor",
              label: (
                <Link to={`${key}/mentor-manage`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/mentor-manage`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Mentor
                  </Title>
                </Link>
              ),
            },
            {
              key: "student",
              label: (
                <Link to={`${key}/student-manage`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/student-manage`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Student
                  </Title>
                </Link>
              ),
            },
          ];
        }

        if (key === "messages") {
          children = [
            {
              key: "notifications",
              label: (
                <Link to={`${key}/notifications`}>
                  <Title
                    level={4}
                    style={{
                      textDecoration: "none",
                      color: location.pathname.includes(`${key}/notifications`)
                        ? "#1890ff"
                        : "white",
                    }}
                  >
                    Notifications
                  </Title>
                </Link>
              ),
            },
          ];
        }

        item = {
          label: children ? (
            <Title
              level={4}
              style={{
                textDecoration: "none",
                color:
                  key === "users_new" || key === "users"
                    ? "white"
                    : pathname.includes(`/${key}`)
                    ? "#1890ff"
                    : "white",
                margin: "0px 0px 0px 20px",
              }}
            >
              {key === "dfs"
                ? key.toUpperCase()
                : key === "users_new"
                ? "Users"
                : key[0].toUpperCase() + key.substring(1)}
            </Title>
          ) : (
            <Link to={`${key}`}>
              <Title
                level={4}
                style={{
                  textDecoration: "none",
                  color: pathname.includes(`/${key}`) ? "#1890ff" : "white",
                  margin: "0px 0px 0px 20px",
                }}
              >
                {key === "dfs"
                  ? key.toUpperCase()
                  : key[0].toUpperCase() + key.substring(1)}
              </Title>
            </Link>
          ),
          icon: iconMaps[key],
          key,
          children,
        };
      }

      if (item) {
        drawerItems.push(item);
      }
    }

    setItems(drawerItems);
  };

  useEffect(() => {
    const { pathname } = location;

    if (sportsId) {
      fetchItemsHandler(pathname);
    }
  }, [location, sportsId]);

  return (
    <>
      <Drawer
        open={showSidebar}
        closable={false}
        placement="left"
        maskClosable={false}
        className="drawer"
        width={270}
        onClose={() => dispatch(toggleSidebar())}
        mask={false}
      >
        <Menu mode="inline" items={items} />
      </Drawer>
    </>
  );
};

export default Sidebar;
