import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../components/loader";
import { routeConfig, componentMap } from "../constants/route-configuration";

const routes = []

routes.push({
    path: '/*',
    element: <Navigate to='/dashboard' />,
    childRoutes: []
})

const modules = {
    "dashboard": 1,
    "content": 1,
    "kadamba": 1,
    "dfs": 1,
    "settings": 1,
    "users": 0,
    "users_new": 1,
    "streaming": 0,
    "assets": 1,
    "avatar": 1,
    'subscription': 1,
    "usq": 1,
    "messages": 1,
}

for (const [module, val] of Object.entries(modules)) {

    let route

    if (val === 1) {

        const childRoutes = []

        for (const [key, val] of Object.entries(routeConfig[module])) {

            const TComponent = componentMap[key]

            const child = {
                path: val,
                element: <Suspense fallback={<Loader />}><TComponent /></Suspense>
            }

            childRoutes.push(child)
        }

        const TComponent = componentMap[module]

        route = {
            path: module,
            element: <Suspense fallback={<Loader />}><TComponent /></Suspense>,
            children: childRoutes
        }

    }

    if (route) {
        routes.push(route)
    }

}

const PrivateRouteConfig = () => useRoutes(routes)

const PrivateRoutes = () => {

    return (
        <PrivateRouteConfig />
    )

}

export { PrivateRoutes }

