import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../reducers/loginSlice";
import sidebarReducer from "../reducers/sidebarSlice"
import alertReducer from "../reducers/alertSlice"
import sportsReducer from "../reducers/sportsSlice"
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import persistStore from "redux-persist/es/persistStore";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga";

const authPersistConfig = {
    key: 'isLoggedIn',
    storage
}

const sportsConfig = {
    key: 'sportsDetails',
    storage
}

const persistedLoginReducer = persistReducer(authPersistConfig, loginReducer)
const persistedSportsReducer = persistReducer(sportsConfig, sportsReducer)

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        isLoggedIn: persistedLoginReducer,
        showSidebar: sidebarReducer,
        alertDetails: alertReducer,
        sportsDetails: persistedSportsReducer
    }, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware)
})

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga);

export { store, persistor }
