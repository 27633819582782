import { login as handleLogin } from '../../utils/utils';

const login = async (email, password) => {

    try {

        const response = await handleLogin({ email, password })
        return response

    }

    catch (err) {
        return err.response.data
    }

}

export { login }
