import React, { Suspense } from "react";
import Loader from "../components/loader";
import { Navigate, useRoutes } from "react-router-dom";

const Login = React.lazy(() => import('../modules/login'))

const routes = []

routes.push({
    path: 'login',
    element: <Suspense fallback={<Loader />}><Login /></Suspense>,
    childRoutes: []
})

routes.push({
    path: '/*',
    element: <Navigate to='/login' />,
    childRoutes: []
})

const PublicRouteConfig = () => useRoutes(routes)

const PublicRoutes = () => {

    return (
        <PublicRouteConfig />
    )

}

export { PublicRoutes }