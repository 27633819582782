import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { message } from 'antd'
import { BrowserRouter } from "react-router-dom";
import { PrivateRoutes } from './router/private.route.config';
import { selectIsLoggedIn } from './selectors/login'
import { useSelector } from "react-redux"
import { PublicRoutes } from './router/public.route.config';
import { fetchSportsList } from './utils/utils';
import { handleSportsList } from './reducers/sportsSlice';
import Layout from './components/layout';
import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.less'
import ScrollToTop from './utils/ScrollToTop';

function App() {

  const dispatch = useDispatch()
  const authed = useSelector(selectIsLoggedIn)

  const fetchList = async () => {

    try {

      const res = await fetchSportsList()
      const { data } = res
      dispatch(handleSportsList(data))

    }

    catch (err) {

      return err

    }

  }

  message.config({
    top: 10,
    duration: 2,
    rtl: true,
    maxCount: 1
  })

  useEffect(() => {

    if (authed) {
      fetchList()
    }

  }, [authed])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {!authed && <PublicRoutes />}
        {authed && <Layout><PrivateRoutes /></Layout>}
      </BrowserRouter>
    </>
  )
}

export default App;

