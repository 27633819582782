// import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import { setLogin } from "../../reducers/loginSlice";
import { login } from "../requests/login";

function* handleLogin(action) {
  try {
    const result = yield call(
      login,
      action.payload.email,
      action.payload.password
    );

    yield put(setLogin(result));
  } catch (err) {
    console.log(err);
  }
}

function* watcherLoginSaga() {
  yield takeEvery("LOGIN", handleLogin);
}

export default watcherLoginSaga;
